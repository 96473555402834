export const BASE_URL = 'https://api.postapay.app/apps/merchants-portal/api/v1/'

export const AGENCY_URL = ' https://api.postapay.app/apps/merchants-portal/api/v1/'

export const KYC_DOCUMENTS = 'https://api.postapay.app/apps/kyc/api/v1/kyc-docs/'

export const REQUEST_BASE_URL = BASE_URL

export const AUTH_BASE_URL = BASE_URL + 'auth/login/'

export const OPERATOR_AUTH = AUTH_BASE_URL + 'operator/'

export const AUTH_URL = AUTH_BASE_URL

export const MERCHANT_INFO = BASE_URL + 'merchants/'

export const CODE_VERIFY_URL = AUTH_BASE_URL + "otp/"

export const REFRESH_URL = AUTH_BASE_URL + "/token/"

export const LOGOUT_URL = AUTH_BASE_URL + "/logout/"

export const ACCOUNTS = BASE_URL + "accounts/"

export const CLIENTS = `${ACCOUNTS}?`

export const REPORTS = `${ACCOUNTS}/reports`

export const CLIENT_REPORTS = `${ACCOUNTS}client_reports`

export const TICKETS = `${BASE_URL}tickets`

export const GRAPHS = `${BASE_URL}graphs`

export const SYSTEM_HEALTH = `${GRAPHS}/system_health`

export const TRANSACTIONS_BY_CHANNEL = `${GRAPHS}/transactions-by-channel`

export const TRANSACTION_TRENDS = `${GRAPHS}/transaction_trends`

export const DASHBOARD = `${GRAPHS}/dashboard`

export const PRODUCTS = `${BASE_URL}products`

export const TRANSACTIONS = `${BASE_URL}transactions`

export const TRANSFERS = `${BASE_URL}transfers`

export const INVOICES = `${BASE_URL}invoices`

export const REQUEST_BY_ALIAS = `${INVOICES}/request-payment-by-alias/`

export const REQUEST_BY_ACC = `${INVOICES}/request-payment-by-account/`

export const TRANSACTIONS_IN = `${TRANSACTIONS}/transactions-in`

export const ALL_TRANSCTION = `${TRANSACTIONS}/transactions-all`

export const TRANSACTIONS_OUT = `${TRANSACTIONS}/transactions-out`

export const SHOP = BASE_URL + 'ecommerce/shop/';

export const BRANCH = BASE_URL + 'ecommerce/branch/';

export const WITHDRAWALS = BASE_URL + 'withdrawals/';

export const BULK = BASE_URL + 'bulk-payments/';

export const STAGE_BULK_PAYMENTS = BULK + 'stage/';

export const PAYMENTS = BASE_URL + 'payments'

export const AGENCYBANKING = `${AGENCY_URL}agency-banking/`

export const AGENCYDEPOSIT = `${AGENCYBANKING}withdraw-to-agent/`